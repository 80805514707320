<template>
  <div>
    <v-row class="primary text-center">
      <v-col class="d-flex justify-center">
        <span class="text-lg-h1 text-md-h3 text-sm-h4 text-h5"
          >Wer soll den Cumshot bekommen?</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center text-h6 secondary--text">
        Es wurden bereits {{ counter }} Cumshots verteilt! Der letzte ging
        an&nbsp;<span class="font-weight-bold"> {{ recentWinner }}</span
        >!
      </v-col>
    </v-row>

    <!-- SM and down view -->
    <div class="hidden-md-and-up">
      <v-row>
        <v-col>
          <v-form v-model="validPlayer" ref="playerForm">
            <v-text-field
              class="mx-7"
              label="Spieler"
              v-model="player"
              :rules="[notEmpty]"
              color="secondary"
            ></v-text-field>
          </v-form>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex justify-end">
          <v-btn
            elevation="2"
            @click="addPlayer"
            :disabled="!validPlayer"
            color="primary"
            >Add</v-btn
          >
        </v-col>
        <v-col class="d-flex justify-start">
          <v-btn
            elevation="2"
            @click="raffle"
            :disabled="players.length < 2"
            color="secondary"
            >Raffle</v-btn
          >
        </v-col>
      </v-row>
      <v-row>
        <v-col class="mx-5" l v-for="(player, i) in players" :key="i">
          <v-card class="d-flex justify-center mb-2" color="primary">
            <v-container>
              <v-row>
                <v-col class="d-flex justify-center">
                  <span class="text-truncate">
                    {{ player }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- MD and up view -->
    <div class="hidden-sm-and-down">
      <v-row class="d-flex justify-center">
        <v-col cols="2">
          <v-form v-model="validPlayer" ref="playerForm">
            <v-text-field
              label="Spieler"
              v-model="player"
              :rules="[notEmpty]"
              color="secondary"
            ></v-text-field>
          </v-form>
        </v-col>
        <v-col cols="2" class="d-flex align-center justify-center">
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            @click="addPlayer"
            :disabled="!validPlayer"
            color="primary"
            >Add</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            elevation="2"
            @click="raffle"
            :disabled="players.length < 2"
            color="secondary"
            >Raffle</v-btn
          >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="4">
          <v-card
            class="d-flex justify-center mb-2"
            v-for="(player, i) in players"
            :key="i"
            color="primary"
          >
            <v-container>
              <v-row>
                <v-col class="d-flex justify-center">
                  <span class="text-truncate">
                    {{ player }}
                  </span>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-overlay v-model="showOverlay">
      <v-card color="secondary primary--text mx-7">
        <v-container>
          <v-row>
            <v-col class="text-h5 font-weight-bold">
              <span class="d-flex justify-center">
                Glückwunsch,&nbsp;
                <span class="font-weight-bold">{{ winner }}</span
                >&nbsp;bekommt einen Cumshot!
              </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="d-flex justify-center">
              <v-btn @click="showOverlay = false" color="primary">
                Schließen
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "Home",

  data() {
    return {
      players: [],
      player: null,
      validPlayer: false,
      winner: null,
      showOverlay: false,
      counter: -1,
      recentWinner: "",
    };
  },

  mounted() {
    this.getCurrentCounter();
    this.getRecentWinner();
  },

  methods: {
    addPlayer() {
      if (this.player.indexOf(",") > -1) {
        let array = this.player.split(",");
        for (let i = 0; i < array.length; i++) {
          this.players.push(array[i]);
        }
      } else {
        this.players.push(this.player);
      }
      //   this.players.push(this.player);
      this.player = null;
      this.$refs.playerForm.reset();
    },
    notEmpty(input) {
      if (input) {
        return true;
      }
      return "Playername must be set!";
    },

    async raffle() {
      let num = Math.floor(Math.random() * this.players.length);
      this.winner = this.players[num];
      this.showOverlay = true;

      var raw = JSON.stringify({
        increaseBy: 1,
      });

      var requestOptions = {
        method: "POST",
        body: raw,
      };

      let response = await fetch(
        "https://csraffle-restapi.buyardent4me.workers.dev/counter",
        requestOptions
      );

      response = await response.json();
      if (response.state) {
        this.counter = response.counter;
      } else {
        console.log(response.msg);
      }

      //recent winner
      raw = JSON.stringify({
        winner: this.winner,
      });

      requestOptions = {
        method: "POST",
        body: raw,
      };

      response = await fetch(
        "https://csraffle-restapi.buyardent4me.workers.dev/recent",
        requestOptions
      );

      response = await response.json();
      if (response.state) {
        this.recentWinner = this.winner;
      } else {
        console.log(response.msg);
      }
    },
    async getRecentWinner() {
      var requestOptions = {
        method: "GET",
      };

      let response = await fetch(
        "https://csraffle-restapi.buyardent4me.workers.dev/recent",
        requestOptions
      );

      response = await response.json();
      if (response.state) {
        this.recentWinner = response.recentWinner;
      } else {
        console.log(response.msg);
      }
    },
    async getCurrentCounter() {
      var requestOptions = {
        method: "GET",
      };

      let response = await fetch(
        "https://csraffle-restapi.buyardent4me.workers.dev/counter",
        requestOptions
      );

      response = await response.json();
      if (response.state) {
        this.counter = response.counter;
      } else {
        console.log(response.msg);
      }
    },
  },
};
</script>

<style scoped></style>

<template>
  <v-app>
    <v-main id="app">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data() {
    return {};
  },
};
</script>

<style scoped>
#app {
  background-color: var(--v-background-base);
}
</style>

export default {
  primary: {
    base: "#11181d",
  },
  secondary: {
    base: "#13d389",
  },
  error: {
    base: "#f44336",
  },
  warning: {
    base: "#ffd703",
  },
  info: {
    base: "#03A9F4",
  },
  success: {
    base: "#4caf50",
  },
  pending: {
    base: "#8a8a8a",
  },
  background: {
    base: "#20272E",
  },
  text: {
    base: "#fff",
  },
};

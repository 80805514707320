import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import dark from "./vuetify/themes/dark.js";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: dark,
    },
    options: {
      customProperties: true,
    },
  },
});
